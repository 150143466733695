import React from "react";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainImage from "../assets/main.png";
import signalImage from "../assets/signal.png";

const MainCard = () => {
  const navigate = useNavigate();

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  const handleSignalClick = () => {
    navigate("/signal");
  };

  return (
    <div className="bg-gray-900 text-white rounded-3xl flex flex-col h-full overflow-hidden">
      {/* 대시보드 섹션 */}
      <div
        className="h-1/2 p-6 md:p-8 cursor-pointer relative overflow-hidden"
        onClick={handleDashboardClick}
      >
        {/* 이미지 컨테이너 */}
        <div className="absolute inset-0 z-0">
          <img
            src={mainImage}
            alt="Dashboard background"
            className="w-full h-full object-cover"
            style={{ objectPosition: "50% 50%" }}
          />
        </div>

        {/* 반투명 오버레이 */}
        <div className="absolute inset-0 bg-gray-900 opacity-70 z-10"></div>

        {/* 콘텐츠 */}
        <div className="relative z-20 flex flex-col justify-between h-full">
          {/* 데스크톱 레이아웃 */}
          <div className="hidden lg:block">
            <h3 style={{ fontSize: "14px" }} className="mb-2 md:mb-4">
              MORE OFFERS
            </h3>
            <div className="text-white leading-snug">
              <h2 className="text-white mb-4">
                <span style={{ fontSize: "80px" }}>One</span>{" "}
                <span style={{ fontSize: "60px" }}>dashboard,</span>
              </h2>
              <h2 className="text-white mb-4">
                <span style={{ fontSize: "80px" }}>All</span>{" "}
                <span style={{ fontSize: "60px" }}>your FX trading data</span>
              </h2>
            </div>
          </div>

          {/* 모바일 레이아웃 */}
          <div className="lg:hidden flex flex-col justify-center items-center h-full">
            <h2 className="text-white text-3xl font-bold text-center">
              FX Dashboard
            </h2>
          </div>

          {/* 버튼 */}
          <div className="flex items-end">
            <button className="bg-white text-black px-8 py-2 md:px-10 md:py-3 rounded-full flex items-center text-lg md:text-xl font-semibold transition-all duration-300 ease-in-out shadow-lg hover:bg-gray-200 hover:shadow-xl">
              Dashboard
              <ChevronRight className="ml-5 w-6 h-7 md:w-7 md:h-7" />
            </button>
          </div>
        </div>
      </div>

      {/* 시그널 섹션 */}
      <div
        className="h-1/2 p-6 md:p-8 cursor-pointer relative overflow-hidden"
        onClick={handleSignalClick}
      >
        {/* 이미지 컨테이너 */}
        <div className="absolute inset-0 z-0">
          <img
            src={signalImage}
            alt="Signal background"
            className="w-full h-full object-cover"
            style={{ objectPosition: "50% 50%" }}
          />
        </div>

        {/* 반투명 오버레이 */}
        <div className="absolute inset-0 bg-gray-900 opacity-70 z-10"></div>

        {/* 콘텐츠 */}
        <div className="relative z-20 flex flex-col justify-between h-full">
          {/* 데스크톱 레이아웃 */}
          <div className="hidden lg:block">
            <h3 style={{ fontSize: "14px" }} className="mb-2 md:mb-4">
              SIGNAL SERVICE
            </h3>
            <div className="text-white leading-snug">
              <h2 className="text-white mb-4">
                <span style={{ fontSize: "80px" }}>Real-time</span>
              </h2>
              <h2 className="text-white mb-4">
                <span style={{ fontSize: "60px" }}>Trading Signals</span>
              </h2>
            </div>
          </div>

          {/* 모바일 레이아웃 */}
          <div className="lg:hidden flex flex-col justify-center items-center h-full">
            <h2 className="text-white text-3xl font-bold text-center">
              Signal Service
            </h2>
          </div>

          {/* 버튼 */}
          <div className="flex items-end">
            <button className="bg-white text-black px-8 py-2 md:px-10 md:py-3 rounded-full flex items-center text-lg md:text-xl font-semibold transition-all duration-300 ease-in-out shadow-lg hover:bg-gray-200 hover:shadow-xl">
              Signal
              <ChevronRight className="ml-5 w-6 h-7 md:w-7 md:h-7" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainCard;
