import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Signal = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  if (!currentUser) {
    return (
      <div className="w-full h-full bg-white rounded-lg shadow-lg p-6">
        <div className="flex flex-col items-center justify-center space-y-4 h-full">
          <h2 className="text-2xl font-bold text-gray-800">Signal Service</h2>
          <p className="text-center text-gray-600 max-w-md">
            로그인이 필요한 서비스입니다. 로그인하시면 실시간 시그널 서비스를
            이용하실 수 있습니다.
          </p>
          <button
            onClick={handleLoginRedirect}
            className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
          >
            로그인하기
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full bg-white rounded-lg shadow-lg">
      <div className="p-6 flex flex-col h-full">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Signal Service</h2>
        </div>
        <div className="flex-grow">
          <iframe
            src="https://siglab.net/sample/strategy"
            className="w-full h-[calc(100vh-200px)] rounded-lg"
            style={{ border: "none" }}
            title="Signal Service"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Signal;
