import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Home,
  Laptop,
  FileText,
  Crown,
  Bell,
  LogIn,
  LogOut,
  ChevronLeft,
  ChevronRight,
  User,
  BarChart3,
} from "lucide-react";
import largeLogo from "../assets/atflogo.png";
import smallLogo from "../assets/atflogo.png";
import { useAuth } from "../contexts/AuthContext";

const NavigationBar = ({ isVisible, toggleNav }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleAuthAction = async () => {
    if (currentUser) {
      try {
        await logout();
        navigate("/");
      } catch (error) {
        console.error("Failed to log out", error);
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {/* Desktop Navigation */}
      <nav
        className={`fixed left-4 sm:left-6 md:left-8 top-4 sm:top-6 md:top-8 h-[calc(100vh-2rem)] sm:h-[calc(100vh-3rem)] md:h-[calc(100vh-4rem)] transition-all duration-300 z-50
        ${isVisible ? "w-44" : "w-20"}
        overflow-visible hidden lg:block`}
      >
        <div className="h-full relative bg-black rounded-3xl overflow-hidden flex flex-col">
          <svg
            className="absolute top-0 left-0 w-full h-full"
            viewBox="0 0 202 887"
            preserveAspectRatio="xMidYMid slice"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.7" filter="url(#filter0_f_1_368)">
              <rect
                x="60.3247"
                y="60.1408"
                width="81.0149"
                height="804.005"
                rx="24"
                fill="#0C0B0B"
              />
            </g>
            <rect
              x="9.3396"
              y="11.0227"
              width="132"
              height="851.999"
              rx="24"
              fill="#0C0B0B"
            />
            <defs>
              <filter
                id="filter0_f_1_368"
                x="0.324707"
                y="0.140808"
                width="201.015"
                height="924.005"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="30"
                  result="effect1_foregroundBlur_1_368"
                />
              </filter>
            </defs>
          </svg>
          <div className="relative z-10 flex flex-col items-center w-full h-full py-20">
            {/* Logo area */}
            <div className="mb-10 w-full flex justify-center">
              {isVisible ? (
                <img
                  src={largeLogo}
                  alt="Large Logo"
                  className="transition-all duration-300 w-auto"
                  style={{ height: "10rem" }}
                />
              ) : (
                <img
                  src={smallLogo}
                  alt="Small Logo"
                  className="transition-all duration-300 w-auto"
                  style={{ height: "5rem" }}
                />
              )}
            </div>
            {/* Navigation items */}
            <div className="flex flex-col items-center space-y-11 flex-grow">
              <NavItem to="/" icon={Home} text="HOME" isVisible={isVisible} />
              <NavItem
                to="/dashboard"
                icon={Laptop}
                text="DASHBOARD"
                isVisible={isVisible}
              />
              <NavItem
                to="/news"
                icon={FileText}
                text="NEWS"
                isVisible={isVisible}
              />
              <NavItem
                to="/broker"
                icon={Crown}
                text="BROKER"
                isVisible={isVisible}
              />
              <NavItem
                to="/signal"
                icon={BarChart3}
                text="SIGNAL"
                isVisible={isVisible}
              />
              {currentUser && (
                <NavItem
                  to="/profile"
                  icon={User}
                  text="PROFILE"
                  isVisible={isVisible}
                />
              )}
            </div>
            {/* Login/Logout button */}
            <div className="mt-auto">
              <button
                onClick={handleAuthAction}
                className="text-white hover:text-gray-300 flex flex-col items-center"
              >
                {currentUser ? (
                  <>
                    <LogOut size={isVisible ? 36 : 28} strokeWidth={2.5} />
                    {isVisible && (
                      <span className="mt-2 text-xs font-bold text-lg">
                        LOGOUT
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <LogIn size={isVisible ? 36 : 28} strokeWidth={2.5} />
                    {isVisible && (
                      <span className="mt-2 text-xs font-bold text-lg">
                        LOGIN
                      </span>
                    )}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <button
          onClick={toggleNav}
          className={`absolute top-4 ${
            isVisible ? "right-4" : "left-4"
          } z-50 bg-gray-800 text-white p-2 rounded-full transition-all duration-300`}
        >
          {isVisible ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
        </button>
      </nav>

      {/* Mobile Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 bg-black text-white z-50 lg:hidden w-full max-w-[100vw]">
        <div className="flex justify-around items-center py-2 overflow-x-auto">
          <NavItem to="/" icon={Home} text="HOME" isMobile={true} />
          <NavItem
            to="/dashboard"
            icon={Laptop}
            text="DASHBOARD"
            isMobile={true}
          />
          <NavItem to="/news" icon={FileText} text="NEWS" isMobile={true} />
          <NavItem to="/broker" icon={Crown} text="BROKER" isMobile={true} />
          <NavItem
            to="/signal"
            icon={BarChart3}
            text="SIGNAL"
            isMobile={true}
          />
          {currentUser && (
            <NavItem to="/profile" icon={User} text="PROFILE" isMobile={true} />
          )}
        </div>
      </nav>
    </>
  );
};

// NavItem component
const NavItem = ({
  to,
  icon: Icon,
  text,
  isVisible = true,
  isMobile = false,
}) => (
  <Link
    to={to}
    className={`flex flex-col items-center text-white hover:text-gray-300 ${
      isMobile ? "px-2" : ""
    }`}
  >
    <Icon
      size={isMobile ? 20 : isVisible ? 36 : 28}
      strokeWidth={isMobile ? 1.5 : 2.5}
    />
    {(isVisible || isMobile) && (
      <span
        className={`mt-1 ${
          isMobile ? "text-[8px] font-normal" : "text-xs font-bold text-lg"
        }`}
      >
        {text}
      </span>
    )}
  </Link>
);

export default NavigationBar;
